* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-touch-callout: none;
  font-family: IBM Plex Sans;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #f7f8fd;
}
