.menus {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__selected {
    background-color: #f7f8fd;
    :global {
      .ant-menu-title-content {
        color: #0d6efd !important;
      }

      svg {
        path {
          stroke: #0d6efd;
        }
      }
    }
  }

  :global {
    .ant-menu-item {
      gap: 16px;
      margin: 0 !important;
      padding: 0 16px !important;
      width: 100% !important;
      border-radius: 8px !important;

      &:after {
        border-right: solid 3px red;
      }

      .ant-menu-title-content {
        font-size: 16px;
        color: #6b7194;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .menus {
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    :global {
      .ant-menu-item {
        flex-direction: column;
        height: auto;
        gap: 8px;

        &.ant-menu-item-selected {
          background-color: transparent;
          border-color: transparent;
          color: transparent;
        }

        .ant-menu-title-content {
          line-height: 16px;
        }
      }
    }
  }
}
