.form {
  :global {
    .ant-form-item {
      margin-bottom: 0 !important;

      .ant-form-item-required {
        color: #282556;
        font-size: 14px;
        font-weight: 500;

        &::before {
          display: none !important;
        }

        &::after {
          display: inline-block;
          margin-inline-start: 4px;
          color: #ff4d4f;
          font-size: 16px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: "*";
          visibility: visible;
        }
      }
    }
  }
}

.modal {
  :global {
    .ant-modal-content {
      margin: 32px 0;
    }
  }
}
