.container {
  height: 48px !important;

  &__contained {
    color: white;
    border: none;

    &.container__primary {
      background: linear-gradient(180deg, #6e83b9 0%, #4660a4 100%) !important;
      color: #fff !important;
    }

    &.container__danger {
      background: #dc3545 !important;
      color: #fff;
    }
  }

  &__outlined {
    background-color: transparent;
    border: solid 1px #b6b9ce;

    &.container__primary {
      color: #4660a4 !important;
      border-color: #4660a4 !important;
    }

    &.container__danger {
      color: #dc3545 !important;
      border-color: #dc3545 !important;
    }
  }
}
