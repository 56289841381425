.container {
  border: solid 1px #e4e6f1;
  border-radius: 8px;

  :global {
    .ant-table {
      border-radius: 8px;

      .ant-table-thead {
        .ant-table-cell {
          background-color: #f3f5fb;
          color: #6b7194;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
