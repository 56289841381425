.otp {
  :global {
    .ant-form-item-control {
      margin: auto;
      width: fit-content !important;

      @media (min-width: 768px) {
        width: 100% !important;
        margin: 0;
      }

      .ant-otp-input {
        width: 56px;
        height: 56px;
        font-size: 24px;
      }
    }
  }
}
