.container {
  :global {
    ol {
      align-items: center;

      .ant-breadcrumb-link {
        color: #6b7194;
        font-size: 20px;
        font-weight: 600;
        height: fit-content;
      }

      li:last-child {
        a {
          color: #160042;
          font-size: 24px;
        }
      }
    }
  }
}
